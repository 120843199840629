<template>
  <div>
    <v-card class="rounded-lg card-border my-1 py-2 px-2" light elevation="2">
      <v-row class="mb-1">
        <v-col cols="5" class="d-flex justify-center align-center">
          <v-img
            :src="
              imgURL +
              '/' +
              userProperty.user_id +
              '/' +
              userProperty.basic_property_id +
              '/' +
              userProperty.image_name
            "
            width="100px"
            height="90px"
            class="rounded-lg"
          ></v-img>
        </v-col>
        <v-col cols="7" class="d-flex flex-column mt-2">
          <v-row class="subtitle-2 mt-1">
            <span v-if="userProperty.bedroom">
              {{ userProperty.bedroom }} BHK
            </span>
            &nbsp;{{ userProperty.propertytype.property_name }} for
            {{ userProperty.pfor }}
          </v-row>
          <v-row class="d-flex flex-column pl-1">
            <div class="caption blue-grey--text">
              At {{ userProperty.locality }} {{ userProperty.city }}
            </div>
            <div class="body-2">₹ {{ userProperty.price }}</div>
          </v-row>
          <v-row class="d-flex flex-column mb-1 pl-1">
            <div class="caption blue-grey--text">
              Owner: {{ userProperty.name }}
              <v-btn :href="'tel:+91-' + userProperty.contact" plain>
                <span>{{ userProperty.contact }} </span>
              </v-btn>
            </div>
          </v-row>
          <v-divider></v-divider>
          <v-row align-sm="center" justify-content="start" class="px-1 mt-1">
            <v-btn
              v-if="userProfile.role == 1"
              icon
              color="info"
              :to="{
                name: 'adminProperty',
                params: { id: userProperty.basic_property_id },
              }"
            >
              <v-icon left small> far fa-eye </v-icon>
            </v-btn>
            <v-btn
              v-else
              icon
              color="info"
              text
              :to="{
                name: 'userProperty',
                params: { id: userProperty.basic_property_id },
              }"
            >
              <v-icon left small> far fa-eye </v-icon>
            </v-btn>
            <v-btn
              v-if="userProfile.role == 1"
              icon
              color="success"
              :to="{
                name: 'editAdminProperty',
                params: { id: userProperty.basic_property_id },
              }"
            >
              <v-icon left small> far fa-edit </v-icon>
            </v-btn>
            <v-btn
              v-else
              icon
              color="success"
              :to="{
                name: 'editUserProperty',
                params: { id: userProperty.basic_property_id },
              }"
            >
              <v-icon left small> far fa-edit </v-icon>
            </v-btn>

            <v-btn icon color="red" @click="deletePropertyDialog">
              <v-icon left small> far fa-trash-alt </v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-bottom-sheet
              v-model="sheet"
              inset
              v-if="userProperty.name == null"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon small>fas fa-ellipsis-v </v-icon>
                </v-btn>
              </template>
              <v-sheet class="pa-2 rounded-lg text-center" height="auto">
                <v-progress-linear
                  :active="loading"
                  :indeterminate="loading"
                  color="blue accent-4"
                ></v-progress-linear>
                <v-snackbar
                  v-model="snackbar"
                  :color="snackbar_color"
                  rounded="pill"
                  top
                >
                  {{ text }}

                  <template v-slot:action="{ attrs }">
                    <v-btn
                      color="white"
                      icon
                      v-bind="attrs"
                      @click="snackbar = false"
                    >
                      <v-icon>mdi-window-close</v-icon>
                    </v-btn>
                  </template>
                </v-snackbar>
                <v-row>
                  <v-col cols="11">
                    <div class="title">Add property Owner Details</div>
                  </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row class="my-2 justify-center">
                  <v-col cols="10">
                    <v-form
                      v-on:submit.prevent="ownerContact"
                      ref="form"
                      v-model="valid"
                      lazy-validation
                    >
                      <v-text-field
                        v-model="user.name"
                        :rules="nameRules"
                        label="Full Name"
                        :error-messages="this.error_name"
                        prepend-inner-icon="fas fa-user-tie"
                        rounded
                        outlined
                      />
                      <v-text-field
                        label="Mobile No."
                        v-model="user.contact"
                        :counter="10"
                        :rules="contactRules"
                        :error-messages="this.error_contact"
                        prepend-inner-icon="fas fa-mobile-alt"
                        rounded
                        outlined
                      />
                      <v-btn
                        color="info"
                        type="submit"
                        rounded
                        block
                        @click="validate"
                        :disabled="!valid"
                      >
                        REGISTER
                      </v-btn>
                    </v-form>
                  </v-col>
                </v-row>
              </v-sheet>
            </v-bottom-sheet>
          </v-row>
        </v-col>
      </v-row>
      <!-- <v-divider /> -->
    </v-card>

    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="headline"
          >Are you sure you want to delete this item?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="cancelDeleteDialog"
            >Cancel</v-btn
          >
          <v-btn color="blue darken-1" text @click="deleteProperty">OK</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapState } from "vuex";
import Property from "../api/Property";
export default {
  props: ["userProperty"],
  data: () => ({
    sheet: false,
    imgURL: "https://100milesrealtors.com/routes/storage/images",
    show: false,
    dialogDelete: false,
    rlid: "",
    valid: true,
    loading: false,
    snackbar: false,
    snackbar_color: "",
    text: "",
    error_name: "",
    error_contact: "",
    user: {
      name: "",
      contact: "",
    },
    nameRules: [(v) => !!v || "name is required"],

    contactRules: [
      (v) => !!v || "Contact Number is required",
      (v) => !isNaN(parseInt(v)) || "Contact Number must be digit.",
      (v) =>
        (v && v.length >= 10) || "Contact Number must have atleast 10 digit",
      (v) =>
        (v && v.length <= 10) ||
        "Contact Number exceeds maximum allowed number",
    ],
  }),
  computed: {
    ...mapState({
      userProfile: (state) => state.authenticate.userProfile,
    }),
  },
  methods: {
    deletePropertyDialog() {
      this.dialogDelete = true;
    },
    cancelDeleteDialog() {
      this.dialogDelete = false;
    },
    validate() {
      this.$refs.form.validate();
    },
    ownerContact() {
      this.loading = true;
      Property.ownerContact(this.userProperty.basic_property_id, this.user)
        .then((response) => {
          if (response.status == 201) {
            this.loading = false;
            this.snackbar_color = "success";
            this.snackbar = true;
            this.text = response.data.message;
            window.location.replace("/admin/home");
          }
        })
        .catch((err) => {
          if (err.response) {
            console.log("error data :", err.response.data);
            this.error_message = err.response.data.errors;
            this.error_email = err.response.data.errors.email;
            this.error_contact = err.response.data.errors.contact;
            //console.log('error status :',error.response.status);
          }
          this.loading = false;
          this.snackbar_color = "error";
          this.text = err.response.message;
          this.snackbar = true;
        });
    },
    deleteProperty: function () {
      this.dialogDelete = false;
      console.log(this.userProperty.basic_property_id);
      Property.deleteUserProperty(this.userProperty.basic_property_id)
        .then((response) => {
          if (response.data) {
            this.$router.go(0);
            console.log(response.data);
          }
        })
        .catch((err) => {
          console.log(err.response.data.message);
          // this.snackbar = true;
          //this.snackbar_color = 'error'
        });
    },
  },
};
</script>
